
import { Component, Vue } from 'vue-property-decorator'
import { UserModule } from '@/store/modules/user'

@Component({
  name: 'Dashboard'
})
export default class extends Vue {
  get name() {
    return UserModule.currUserInfo.nickname
  }

  get roles() {
    // return UserModule.roles
    return ["admin","edit"]
  }
}
